import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import UseToast from 'hooks/use-toast';

import useDeviceIdentify from 'hooks/use-deviceidentify';
import UseActivities from 'hooks/use-activities';

// services
import { oauthService } from 'services/auth';
// utils
import { setAuthorization, affiliateMarketingCodeUtils } from 'utils/authentication';

import AppleLogo from 'assets/logo/apple-logo-white.svg';

import classes from './AppleButton.module.scss';

/* global AppleID */
const AppleButton = ({ children, className, callback, nextPage: next, ...props }) => {
	const router = useRouter();
	const nextPage = router?.state?.next || router?.query?.next || next;
	const { toast, ToastContainer } = UseToast();
	
	const { sendUTM } = UseActivities();
	const { handleDeviceIdentification } = useDeviceIdentify();

	const { mutate: calloauthApi, isLoading: oatuhLoading } = useMutation(oauthService, {
		onSuccess: (data) => {
			affiliateMarketingCodeUtils.delete();
			setAuthorization(data.data);
			sendUTM();
			handleDeviceIdentification();
			if (callback) {
				callback();
			} else {
				nextPage ? router.replace(nextPage) : router.replace('/');
			}
		},
		onError: (error) => {
			toast(error?.response?.data?.data || 'Something went wrong! please try again', 'Alert');
		}
	});
	useEffect(() => {
		const script = document.createElement('script');
		script.src =
			'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
		script.defer = true;
		document.head.appendChild(script);

		script.onload = () => {
			AppleID.auth.init({
				clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
				scope: 'name email',
				redirectURI: window?.location.origin,
				usePopup: true
			});
		};

		return () => {
			document.head.removeChild(script);
		};
	});
	const appleLoginHandler = async () => {
		const data = await AppleID.auth.signIn();
		if (data && data.authorization) {
			const affiliate_marketing_code = affiliateMarketingCodeUtils.get();
			calloauthApi({
				body: {
					...data.authorization,
					client_id: process.env.REACT_APP_APPLE_CLIENT_ID,
					affiliate_marketing_code: affiliate_marketing_code || null
				},
				service: 'apple'
			});
		} else {
			toast('Something went wrong! please try again', 'Alert');
		}
	};
	return (
		<>
			<ToastContainer />
			<button
				disabled={oatuhLoading}
				onClick={appleLoginHandler}
				className={`${classes['apple-button']} ${className || ' '}`}
				{...props}>
				<AppleLogo data-testid="apple-logo" />
				{children}
			</button>
		</>
	);
};

export default AppleButton;
