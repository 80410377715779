import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import UseToast from 'hooks/use-toast';

import useDeviceIdentify from 'hooks/use-deviceidentify';

// services
import { oauthService } from 'services/auth';
// utils
import { setAuthorization, affiliateMarketingCodeUtils } from 'utils/authentication';

import FacebookLogo from 'assets/logo/facebook-logo.svg';

import classes from './FacebookButton.module.scss';



/* global FB */
const FacebookButton = ({ children, className, callback, nextPage: next, ...props }) => {
	const router = useRouter();
	const nextPage = router?.state?.next || router?.query?.next || next;
	const { handleDeviceIdentification } = useDeviceIdentify();
	const { toast, ToastContainer } = UseToast();
	const { mutate: calloauthApi, isLoading: oatuhLoading } = useMutation(oauthService, {
		onSuccess: (data) => {
			affiliateMarketingCodeUtils.delete();
			setAuthorization(data.data);
			handleDeviceIdentification();
			if (callback) {
				callback();
			} else {
				nextPage ? router.replace(nextPage) : router.replace('/');
			}
		},
		onError: (error) => {
			toast(error?.response?.data?.data || 'Something went wrong! please try again', 'Alert');
		}
	});
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://connect.facebook.net/en_US/sdk.js';
		script.defer = true;
		document.head.appendChild(script);
		script.onload = () => {
			FB.init({
				appId: process.env.REACT_APP_FACEBOOK_APP_ID,
				cookie: true,
				xfbml: true,
				version: 'v18.0'
			});

			FB && FB?.AppEvents?.logPageView();
		};

		return () => {
			// Cleanup code to remove the script from the head when the component unmounts
			document.head.removeChild(script);
		};
	}, []);
	const facebookLoginHandler = () => {
		FB && FB?.login(
			function (response) {
				if (response.status === 'connected') {
					const affiliate_marketing_code = affiliateMarketingCodeUtils.get();
					calloauthApi({
						body: {
							access_token: response.authResponse.accessToken,
							affiliate_marketing_code: affiliate_marketing_code || null
						},
						service: 'facebook'
					});
				}
			},
			{
				scope: 'email',
				return_scopes: true
			}
		);
	};
	return (
		<>
			<ToastContainer />
			<button
				disabled={oatuhLoading}
				onClick={facebookLoginHandler}
				className={`${classes['facebook-button']} ${className || ' '}`}
				{...props}>
				<FacebookLogo data-testid="facebook-logo" />
				{children}
			</button>
		</>
	);
};

export default FacebookButton;
