import React, { useState, useEffect } from 'react';
import Script from 'next/script';
// hooks
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import UseToast from 'hooks/use-toast';
import UseActivities from 'hooks/use-activities';

import useDeviceIdentify from 'hooks/use-deviceidentify';

// services
import { oauthService } from 'services/auth';
// utils
import { setAuthorization, affiliateMarketingCodeUtils } from 'utils/authentication';

// constans
import classes from './GoogleButton.module.scss';

import GoogleLogo from 'assets/logo/google-logo.svg';

/* global google */
const GoogleButton = ({ children, className, callback, nextPage: next, ...props }) => {
	const [client, setClient] = useState(null);
	const router = useRouter();
	const nextPage = router?.state?.next || router?.query?.next || next;
	
	const { handleDeviceIdentification } = useDeviceIdentify();
	const { sendUTM } = UseActivities();

	const { toast, ToastContainer } = UseToast();
	const { mutate: calloauthApi, isLoading: oatuhLoading } = useMutation(oauthService, {
		onSuccess: (data) => {
			affiliateMarketingCodeUtils.delete();
			setAuthorization(data.data);
			handleDeviceIdentification();
			sendUTM();
			if (callback) {
				callback();
			} else {
				nextPage ? router.replace(nextPage) : router.replace('/');
			}
		},
		onError: (error) => {
			toast(error?.response?.data?.data || 'Something went wrong! please try again', 'Alert');
		}
	});

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		script.defer = true;
		document.head.appendChild(script);
		script.onload = () => {
			const googleClient = google.accounts.oauth2.initTokenClient({
				client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
				scope:
					'email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
				callback: handleTokenCallback
			});
			setClient(googleClient);
		};

		return () => {
			// Cleanup code to remove the script from the head when the component unmounts
			document.head.removeChild(script);
		};
	}, []);
	const handleTokenCallback = (response) => {
		const affiliate_marketing_code = affiliateMarketingCodeUtils.get();

		calloauthApi({
			body: {
				access_token: response.access_token,
				affiliate_marketing_code: affiliate_marketing_code || null
			},
			service: 'google'
		});

		// return response;
	};
	// const [client] = useState();

	const googleLoginHandler = async () => {
		client && client.requestAccessToken();
	};

	return (
		<>
			<ToastContainer />
			<button
				disabled={oatuhLoading}
				onClick={googleLoginHandler}
				className={`${classes['google-button']} ${className || ' '}`}
				{...props}>
				<GoogleLogo data-testid="google-logo" />
				{children}
			</button>
			<Script async defer src="https://accounts.google.com/gsi/client" type="module" />
		</>
	);
};

export default GoogleButton;
